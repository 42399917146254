<template>
  <div class="p-grid">
    <div class="p-col-12 p-md-6">
      <h3><i class="pi pi-users" style="font-size: 1.6rem"></i> Usuários</h3>
    </div>
  </div>

  <div class="p-grid">
    <!-- Listagem de Usuários -->
    <div class="p-col-12 p-md-8">
      <DataTable sortMode="single" sortField="name" :sortOrder="1" :value="usuarios" :loading="loading">
        <Column :sortable="true" field="name" header="Nome" class="col-nome">
          <template #body="slotProps">
            {{ slotProps.data.name }} ({{ (!slotProps.data.imobiliaria)?'Corretora':slotProps.data.imobiliaria_item.nome }})
          </template>
        </Column>
        <Column :sortable="true" field="email" header="E-Mail" class="col-email"></Column>
        <Column :sortable="true" field="permissao_item.nome" header="Permissão" class="col-permissao"></Column>
        <Column class="col-actions">
          <template #body="slotProps">
            <Button
              icon="pi pi-pencil"
              class="p-button-rounded p-button-warning p-mr-2"
              v-tooltip.left="'Alterar Usuário'"
              @click="$router.push({ path: `/usuario/${slotProps.data.id}` })"
            />
            <Button v-if="this.$auth.userCan('USUARIOS_EXCLUIR')"
              v-tooltip="'Excluir Usuário'"
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger"
              @click="excluir($event, slotProps.data)"
            />
          </template>
        </Column>
        <template #empty>
            Nenhum Usuário encontrado.
        </template>
      </DataTable>
    </div>

    <!-- Formulário de Cadastro de Usuários -->
    <div class="p-col-12 p-md-4">
      <div class="card p-fluid">
        <h5 v-if="cadastro">Cadastro de Novo Usuário</h5>
        <h5 v-else>Alteração de Usuário</h5>

        <form class="p-grid" autocomplete="no">
          <div class="p-field p-md-6">
            <label for="nome">Nome</label>
            <InputText id="nome" type="text" v-model="nome" autocomplete="no" />
          </div>

          <div class="p-field p-md-6">
            <label for="permissao">Permissão</label>
            <Dropdown
              id="permissao"
              v-model="permissao"
              :options="permissoes"
              optionLabel="nome"
              optionValue="id"
              placeholder="Selecione"
            />
          </div>

          <div class="p-field p-md-12">
            <label for="pessoa">Pessoa <small>(opcional)</small></label>
            <Dropdown
              id="pessoa"
              v-model="pessoa"
              :options="pessoas"
              optionLabel="nome"
              optionValue="id"
              placeholder="Selecione"
            />
          </div>

          <div class="p-field p-md-12" v-if="this.$auth.userCan('USUARIOS_GLOBAL')">
            <label for="imobiliaria">Imobiliária</label>
            <Dropdown
              id="imobiliaria"
              v-model="imobiliaria"
              :options="imobiliarias"
              :showClear="true"
              optionLabel="nome"
              optionValue="id"
              placeholder="Nenhuma"
            />
          </div>

          <div class="p-field p-md-12">
            <label for="email">E-Mail</label>
            <InputText id="email" type="text" v-model="email" autocomplete="no" />
          </div>

          <div class="p-field p-md-6">
            <label for="senha1">Senha</label>
						<Password v-model="senha" :feedback="false" autocomplete="no" toggleMask></Password>
          </div>

          <div class="p-field p-md-6">
            <label for="senha2">Confirmar Senha</label>
						<Password v-model="senhaConf" :feedback="false" autocomplete="no" toggleMask ></Password>
          </div>


          <div class="p-md-12" v-if="cadastro">
            <Button
              v-if="this.$auth.userCan('USUARIOS_CADASTRAR')"
              id="buttonGravarUsuario"
              label="Gravar Novo Usuário"
              icon="pi pi-check"
              class="p-button-success"
              @click="adicionar"
            ></Button>
          </div>

          <div class="p-md-12" v-else>
            <div class="p-grid">
              <div class="p-md-6">
                <Button
                  v-if="this.$auth.userCan('USUARIOS_CADASTRAR')"
                  id="buttonAlterarUsuario"
                  label="Alterar Usuário"
                  icon="pi pi-check"
                  class="p-button-success"
                  @click="alterar"
                ></Button>
              </div>
              <div class="p-md-6">
                <Button
                  id="buttonCancelAlteracaoUsuario"
                  label="Cancelar"
                  icon="pi pi-times"
                  class="p-button-warning"
                  @click="cancelarAlteracao"
                ></Button>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />
  
</template>

<script>

export default {
  data() {
    return {

      //Controle de Apresentação
      cadastro: true,
      loading: true,

      // Campos do Formulário
      nome: "",
      email: "",
      senha: "",
      senhaConf: "",
      permissao: "",
      pessoa: "",
      imobiliaria: null,

      // Dado selecionado
      usuario: null,

      // Dados cadastrados
      usuarios: [],

      //Chaves estrangeiras
      permissoes: [],
      pessoas: [],
      imobiliarias: [],
      
    };
  },

  mounted() {

    const self = this;

    this.$api.get('/users-permissoes').then(function (response) {

      const dados = response.data;

      if(dados.success) {
        self.permissoes = dados.data;
      }
      
    });

    this.$api.get('/pessoas').then(function (response) {

      const dados = response.data;

      if(dados.success) {

        for(const p of dados.data) {
          if(p.tipo == "IMOBILIARIA")
            self.imobiliarias.push(p);
          else {
            p.nome += " (" + p.tipo + ")" 
            self.pessoas.push(p);
          }
        }

      }
      
    });

    this.$api.get('/users?relationships=imobiliaria_item:nome;permissao_item:nome').then(function (response) {

      const dados = response.data;

      if(dados.success) {

        self.usuarios = dados.data;
        self.loading = false;

      }
      
    });

    this.carregarObjetoRota();
  },

  watch: {

    $route() {
      if(this.$route.name == "usuario" || this.$route.name == "usuarios")
        this.carregarObjetoRota();
    },

    usuario: function (val) {

      if(val == null) {
        this.limparFormulario();
      } else {

        // Altera tela para modo de edição
        this.cadastro = false;
        
        //Popula a tela de alterar Veículo
        this.nome = val.name;
        this.email = val.email;
        this.pessoa = val.pessoa;
        this.permissao = val.permissao;
        this.imobiliaria = val.imobiliaria;

      }

    },

  },

  methods: {

    // // // Métodos padrões para este modelo de tela // // //

    carregarObjetoRota() {

      const self = this;

      if(typeof this.$route.params.id != "string" || this.$route.params.id == "")
        return;

      var interval = setInterval(() => {
        if(!self.loading) {
          clearInterval(interval);

          var _usuario = self.usuarios.filter((obj) => obj.id == self.$route.params.id);

          if(_usuario.length > 0) {
            self.usuario = _usuario[0];
            return;
          }

          self.$api.get('/users/' + self.$route.params.id).then(function (response) {

            const dados = response.data;

            if(dados.success) {

              self.usuario = dados.data;

            } else {
              alert(dados.message);
              self.$router.push({ path: `/usuarios` });
            }
            
          });

        }
      }, 500);

    },

    limparFormulario() {

      //Limpa as variáveis
      this.nome = "",
      this.email = "",
      this.senha = "",
      this.senhaConf = "",
      this.permissao = "",
      this.pessoa = "",
      this.imobiliaria = null,

      // Altera tela para modo de edição
      this.cadastro = true;

    },

    // // // AÇÕES DO USUÁRIO // // //

    cancelarAlteracao() {

      this.usuario = null;

      this.$router.push({ path: `/usuarios` });

    },

    criar_alterar(criar = true) {

      const self = this;

      //colocar restrições: ao criar é necessário informar a senha
      //ao alterar não é necessário colocar senha
      //antes de cadastrar checar se senhas informadas são iguais
      if(criar) {

        if(this.nome.trim() == "" ||
        this.permissao == "" ||
        this.email.trim() == "" ||
        this.senha.trim() == "" ||
        this.senhaConf.trim() == "") {
          self.$toast.add({severity:'error', summary: 'ERRO!', detail: "Todas as informações precisam ser preenchidas!", life: 5000});
          return false;
        }

      } else {

        if(this.nome.trim() == "" ||
        this.permissao == "" ||
        this.email.trim() == "") {
          self.$toast.add({severity:'error', summary: 'ERRO!', detail: "Todas as informações precisam ser preenchidas!", life: 5000});
          return false;
        } else {

          if(this.senha.trim() != "") { // Alterando Senha
            if(this.senhaConf.trim() == "") { //Confirmação de senha não preenchida
              self.$toast.add({severity:'error', summary: 'ERRO!', detail: "A confirmação de senha precisa ser preenchida!", life: 5000});
              return false;
            } else { // Confirmação de senha preenchida
              if(this.senhaConf.trim() != this.senha.trim()) { // Verificação de similaridade entre senha e confirmação
                self.$toast.add({severity:'error', summary: 'ERRO!', detail: "A senha e a confirmação de senha são diferentes!", life: 5000});
                return false;
              }
            }
          }

        }

      }
      
      var dados = {
        'name' : this.nome,
        'email' : this.email,
        'password' : this.senha,
        'permissao': this.permissao,
        'imobiliaria': this.imobiliaria
      };

      if(this.pessoa != null && this.pessoa != "") {
        dados.pessoa = this.pessoa;
      }

      if(!criar) {
        dados['_method'] = 'PUT';  
      }

      this.$api.post('/users' + ((!criar)?'/'+ this.$route.params.id:''), dados).then(function (response) {
       
          if(response.data.success) {
       
            var v = response.data.data;
   
            //v.imobiliaria_item = self.imobiliarias.filter((obj) => obj.id == v.imobiliaria)[0];

            if(criar) {
              
              self.usuarios.unshift(v);
      
            } else {
          
              for(const k of Object.keys(self.usuarios)) {
    
                if(self.usuarios[k].id == v.id) {
                  self.usuarios[k] = v;
                  break;
                }
              }

            }

            self.$toast.add({severity:'success', summary: 'Sucesso!', detail: response.data.message, life: 3000});

            self.cancelarAlteracao();

          } else {
            self.$toast.add({severity:'error', summary: 'ERRO!', detail: response.data.message, life: 5000});
          }

      }).catch(function (erro) {

        if(typeof erro.response != "undefined")
          self.$toast.add({severity:'error', summary: 'ERRO!', detail: erro.response.data.message, life: 5000});

      });

    },

    alterar() {
      this.criar_alterar(false);
    },

    adicionar() {
      this.criar_alterar();
    },

    excluir(event, obj) {

      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Tem certeza que deseja excluir?',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: () => {

          this.$api.post('/users/' + obj.id, {
            '_method' : 'DELETE'
          }).then(function (response) {

            if(response.data.success) {

              for(const k of Object.keys(self.usuarios)) {
                if(self.usuarios[k].id == obj.id) {
                  self.usuarios.splice(k, 1);
                  break;
                }
              }

              self.$toast.add({severity:'success', summary: 'Sucesso!', detail: response.data.message, life: 3000});

            } else {
              self.$toast.add({severity:'error', summary: 'ERRO!', detail: response.data.message, life: 5000});
            }

          }).catch(function (erro) {

            if(typeof erro.response != "undefined")
              self.$toast.add({severity:'error', summary: 'ERRO!', detail: erro.response.data.message, life: 5000});

          });

        }
      });
      
    },
  },
};
</script>

<style>
  .col-email {
      width: 290px;
  }
</style>